import {
  FieldFormV2,
  FieldFormV2Module,
  FieldSchemaFormBuilderV2,
  Footer,
  AsyncAutoCompleteFieldProps,
  SingleCheckboxFieldProps,
  EnumSingleSelectFieldV2Props,
  ReadOnlyFieldProps,
  TextAreaFieldProps,
} from "@ucl/library";
import { FC, useRef, useState } from "react";
import { wildfireEvaluationApiClient } from "../../../lib/apiClients/application/wildfireApplicationApiClient";
import { Intent } from "@blueprintjs/core";
import { AppToaster } from "../../../../foritfied/components/Toast/Toast";
import { closeAdminUpdateDialog } from "./AdminUpdateDialog";
import variables from "../../../../common/config/variables";
import { Predicate, Query } from "@syncfusion/ej2-data";
import { getAuthRequestHeader } from "../../../lib/wildfireBaseApiClient";
import { UserReadDTO } from "../../../../foritfied/lib/apiClients/user/types";
import WildfireSubmitButton from "../../Button/DialogFooterButtons/SubmitButton/SubmitButton";
import WildfireSecondaryButton from "../../Button/DialogFooterButtons/SecondaryButton/SecondaryButton";
import { WildfireEvaluationSteps } from "../../../../foritfied/types/evaluation/Evaluation";
import "./styles.scss";
import { get } from "lodash";

export interface AdminUpdateFormProps {
  wildfireEvaluationId: string;
  homeOwnerId: string;
  auditorId: string;
  currentStatus: string;
  moveableStepOptionsByAdmin: {
    label: string;
    value: WildfireEvaluationSteps;
  }[];
  refreshWildfireApplication: () => Promise<void>;
}

export interface AdminUpdateFormModel {
  updateHomeowner: boolean;
  homeownerId: string;
  updateAuditor: boolean;
  auditorId: string;
  updateStep: boolean;
  step?: number;
}

export const AdminUpdateForm: FC<AdminUpdateFormProps> = (
  props: AdminUpdateFormProps
) => {
  const adminUpdateFormRef =
    useRef<FieldFormV2Module<AdminUpdateFormModel>>(null);

  const adminUpdateFormDefaults: AdminUpdateFormModel = {
    updateHomeowner: false,
    homeownerId: props.homeOwnerId,

    updateAuditor: false,
    auditorId: props.auditorId,

    updateStep: false,
    step: undefined,
  };

  const adminUpdateFormBuilder: FieldSchemaFormBuilderV2<
    AdminUpdateFormModel
  > = (onValueChange, form, errors) => {
    return [
      {
        type: "SingleCheckbox",
        fieldName: "updateHomeowner",
        fieldKey: "updateHomeowner",
        text: "Update Homeowner",
        value: form.updateHomeowner,
        onSubmit: function (value) {
          onValueChange({ ...form, updateHomeowner: !!value }, value, this);
        },
      } as SingleCheckboxFieldProps,
      {
        type: "AsyncAutoComplete",
        fieldName: "ownerId",
        fieldKey: "ownerId",
        hidden: !form.updateHomeowner,
        label: "Homeowner",
        placeholder: "Type to search users...",
        noResultsLabel: "No users found",
        apiBaseUrl: variables.apiBaseUrl,
        odataUrl: `${variables.apiBaseUrl}odata/userodata`,
        getAuthRequestHeader,
        fieldNames: ["id", "fullName"],
        autoCompleteSettings: {
          fields: { text: "fullName", value: "id" },
          itemTemplate: "<table><tr><td>${fullName}</td></tr><table>",
          query: new Query()
            .select(["id", "fullName"])
            .where(new Predicate("isActive", "equal", true))
            .take(20),
        },
        sortBy: "lastName",
        searchBy: (searchText: string) =>
          new Predicate(
            // Build our own fullName since it's a computed property and can't be queried directly
            "concat(concat(firstName, ' '), lastName)",
            "contains",
            searchText,
            true
          ),
        value: form.homeownerId,
        onSubmit: function (value) {
          // Workaround because AsyncAutocompleteFieldValue type is not accurate
          // It actually returns the entire OData record (for user, in this case)
          // When we expect it to just be the value of the "id" field
          const userSelection = value as unknown as UserReadDTO;
          onValueChange(
            { ...form, homeownerId: userSelection?.id },
            value,
            this
          );
        },
        errorMessages: get(errors, "ownerId"),
      } as AsyncAutoCompleteFieldProps,
      {
        type: "SingleCheckbox",
        fieldName: "updateAuditor",
        fieldKey: "updateAuditor",
        text: "Update Auditor",
        value: form.updateAuditor,
        onSubmit: function (value) {
          onValueChange({ ...form, updateAuditor: !!value }, value, this);
        },
      } as SingleCheckboxFieldProps,
      {
        type: "AsyncAutoComplete",
        fieldName: "auditorId",
        fieldKey: "auditorId",
        hidden: !form.updateAuditor,
        label: "Auditor",
        placeholder: "Type to search users...",
        noResultsLabel: "No users found",
        apiBaseUrl: variables.apiBaseUrl,
        odataUrl: `${variables.apiBaseUrl}odata/userodata`,
        getAuthRequestHeader,
        fieldNames: ["id", "fullName"],
        autoCompleteSettings: {
          fields: { text: "fullName", value: "id" },
          itemTemplate: "<table><tr><td>${fullName}</td></tr><table>",
          query: new Query()
            .select(["id", "fullName"])
            .where(
              new Predicate("isActive", "equal", true).and(
                "Role_AsString",
                "equal",
                "WFP Admin"
              )
            )
            .take(20),
        },
        sortBy: "lastName",
        searchBy: (searchText: string) =>
          new Predicate(
            // Build our own fullName since it's a computed property and can't be queried directly
            "concat(concat(firstName, ' '), lastName)",
            "contains",
            searchText,
            true
          ),
        value: form.auditorId,
        onSubmit: function (value) {
          // Workaround because AsyncAutocompleteFieldValue type is not accurate
          // It actually returns the entire OData record (for user, in this case)
          // When we expect it to just be the value of the "id" field
          const userSelection = value as unknown as UserReadDTO;
          onValueChange({ ...form, auditorId: userSelection?.id }, value, this);
        },
        errorMessages: get(errors, "auditorId"),
      } as AsyncAutoCompleteFieldProps,
      {
        type: "SingleCheckbox",
        fieldName: "updateStep",
        fieldKey: "updateStep",
        text: "Update Step",
        value: form.updateStep,
        onSubmit: function (value) {
          onValueChange({ ...form, updateStep: !!value }, value, this);
        },
      } as SingleCheckboxFieldProps,
      {
        type: "ReadOnly",
        label: "Current Status",
        fieldName: "currentStatus",
        fieldKey: "currentStatus",
        hidden: !form.updateStep,
        value: props.currentStatus,
      } as ReadOnlyFieldProps,
      {
        type: "EnumSingleSelect",
        fieldName: "step",
        fieldKey: "step",
        hidden: !form.updateStep,
        label: "Step To Move To",
        value: form.step,
        optionValues: props.moveableStepOptionsByAdmin,
        onSubmit: function (value) {
          onValueChange({ ...form, step: value as number }, value, this);
        },
        errorMessages: get(errors, "step"),
      } as EnumSingleSelectFieldV2Props,
      {
        type: "TextArea",
        fieldName: "stepSelectionFAQ",
        fieldKey: "stepSelectionFAQ",
        label: "Note:",
        hidden: !form.updateStep,
        readOnly: true,
        disabled: true,
        width: "100",
        value:
          "A Wildfire Application can only move to the previous step, or the next step. Please be aware that manually moving steps may cause notifications to not be sent out to 3rd Parties.",
      } as TextAreaFieldProps,
    ];
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<AdminUpdateFormModel>(
    adminUpdateFormDefaults
  );

  const shouldDisableSubmit =
    formState.updateHomeowner !== true &&
    formState.updateAuditor !== true &&
    formState.updateStep !== true;

  return (
    <div className="wildfire-admin-update-dialog-container">
      <FieldFormV2<AdminUpdateFormModel>
        ref={adminUpdateFormRef}
        value={formState}
        fieldSchemaFormBuilder={adminUpdateFormBuilder}
        isDisabled={isLoading}
        onFieldChanged={(form) => {
          setFormState(form);
        }}
        onFormSubmit={async (form) => {
          setFormState(form);
          setIsLoading(true);
          await wildfireEvaluationApiClient
            .submitAdminUpdate(props.wildfireEvaluationId, form)
            .then(async () => {
              AppToaster.show({
                message: "Successfully updated application",
                intent: Intent.SUCCESS,
              });
              closeAdminUpdateDialog(props.wildfireEvaluationId);
              await props.refreshWildfireApplication();
            })
            .catch((error) => {
              AppToaster.show({
                message: "Failed to update application",
                intent: Intent.DANGER,
              });
              throw error;
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
      />
      <Footer>
        <WildfireSecondaryButton
          className="wildfire-admin-update-dialog-cancel-button"
          buttonText="Cancel"
          onClick={() => closeAdminUpdateDialog(props.wildfireEvaluationId)}
        />
        <WildfireSubmitButton
          className="wildfire-admin-update-dialog-submit-button"
          buttonText="Submit"
          onClick={() => adminUpdateFormRef.current?.submit()}
          isLoading={isLoading}
          disabled={shouldDisableSubmit}
        />
      </Footer>
    </div>
  );
};
